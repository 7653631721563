"use strict";
Object.defineProperty(exports, "__esModule", { value: true });

const develop = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:c77c77a7-6158-4190-883a-584298d446ea",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_RMPFPHzI8",
    "aws_user_pools_web_client_id": "6etn70ogcaug5pg1rtmfjac9t6",
    aws_content_delivery: "enable",
    aws_content_delivery_bucket: 'flk-storage-develop',
    aws_content_delivery_bucket_region: 'eu-central-1',
    aws_appsync_region: "eu-central-1",
    aws_appsync_graphqlEndpoint: "https://pyz5tpvt3vglfexlabng3ahsla.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};


const sb1 = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:917246f5-ba7d-4f97-bcc0-c9101dae87f8",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_CBJ2Dcx1w",
    "aws_user_pools_web_client_id": "63dtpd73ie5rls6f93msj1qckq",
    aws_content_delivery: "enable",
    aws_content_delivery_bucket: 'flk-storage-develop',
    aws_content_delivery_bucket_region: 'eu-central-1',
    aws_appsync_region: "eu-central-1",
    aws_appsync_graphqlEndpoint: "https://m4rlooorf5bn5mfdrbfdzojrve.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

const sb2 = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:c97eb808-75d1-4d47-8b65-23e061f0718d",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_aTX63UkpA",
    "aws_user_pools_web_client_id": "3eu0ib2l3snr3ud8ghufcpsble",
    aws_content_delivery: "enable",
    aws_content_delivery_bucket: 'flk-storage-develop',
    aws_content_delivery_bucket_region: 'eu-central-1',
    aws_appsync_region: "eu-central-1",
    aws_appsync_graphqlEndpoint: "https://lbyazdyupvh2dbyalydpivugku.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

const release = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:d2858beb-8a33-4b2a-a94b-bf8901636f43",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_9IveWymlW",
    "aws_user_pools_web_client_id": "63oq92ct9fint95eop8hrfgeg2",
    aws_content_delivery: "enable",
    aws_content_delivery_bucket: 'flk-storage-release',
    aws_content_delivery_bucket_region: 'eu-central-1',
    aws_appsync_region: "eu-central-1",
    aws_appsync_graphqlEndpoint: "https://ipyju6tmuvgsvog3ct7udhjyrq.appsync-api.eu-central-1.amazonaws.com/graphql", // v1.0.1
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};


exports.default = release;
